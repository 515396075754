import React from "react";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import Img from "gatsby-image";

const NotFoundPage = ({ data }) => {
    const goBack = () => {
        window.history.back();
    };

    return (
        <>
            <SEO title="404: Not found" description="Cottonhat is a web team in Lisbon using the latest technologies to develop custom solutions" lang="en" />
            <div class="error-not-found">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="error-inner text-center">
                                <div class="image mb--55">
                                    <Img fixed={data.heroImage.childImageSharp.fixed} alt="404-page" />
                                    <h3 class="heading heading-h3 text-white">Looks like you are lost.</h3>
                                    <div class="error-text mt--20">
                                        <p class="text-white"> It looks like nothing was found at this location.</p>
                                        <div class="error-button-group mt--40">
                                            <a class="brook-btn bk-btn-white btn-sd-size btn-rounded space-between" onClick={goBack}>
                                                Back
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotFoundPage;

export const query1 = graphql`
    query {
        heroImage: file(relativePath: { eq: "image_404.png" }) {
            childImageSharp {
                fixed(width: 450, quality: 100) {
                    base64
                    aspectRatio
                    width
                    height
                    src
                    srcSet
                }
            }
        }
    }
`;
